import React from "react"
import { Link } from "gatsby"
import { ArrowBack, ArrowForward } from "@material-ui/icons"
import { Typography, makeStyles, Theme } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.primary.main
  },
}))

export interface SiblingLinkData {
  direction: "next" | "prev"
  title: string
  link: string
}

export function SiblingLink({ direction, title, link }: SiblingLinkData) {
  const classes = useStyles()
  const inner =
    direction == "prev" ? (
      <>
        <ArrowBack />
        <Typography variant="h5">{title}</Typography>
      </>
    ) : (
      <>
        <Typography variant="h5">{title}</Typography>
        <ArrowForward />
      </>
    )

  return (
    <Link to={link} className={classes.link}>
      {inner}
    </Link>
  )
}
