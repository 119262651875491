import React from "react"
import { Box, makeStyles, Theme } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagram,
  faTwitter,
  faLinkedinIn,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons"

const commonStyle = {
  padding: "10px",
  borderRadius: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "5px",
  marginLeft: "5px",
  width: "36px",
  height: "36px",
  "&:hover": {
    opacity: 0.8
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  facebook: {
    backgroundColor: "#4267B2",
    ...commonStyle,
  },
  instagram: {
    backgroundColor: "#833AB4",
    ...commonStyle,
  },
  twitter: {
    backgroundColor: "#1DA1F2",
    ...commonStyle,
  },
  linkedin: {
    backgroundColor: "#2867B2",
    ...commonStyle,
  },
  icon: {
    color: "#fff",
  },
}))

const icons = {
  facebook: faFacebookF,
  twitter: faTwitter,
  instagram: faInstagram,
  linkedin: faLinkedinIn,
}

export interface SocialLinksData {
  facebook?: string
  twitter?: string
  instagram?: string
  linkedin?: string
}

export default function SocialLinks(data: SocialLinksData = {}) {
  const classes = useStyles()
  return (
    <Box display="flex">
      {Object.keys(data).map((key, i) => (
        <a href={data[key]} target="_blank" rel="noopener" className={classes[key]}>
          <FontAwesomeIcon
            size="1x"
            key={"sociallink-" + i}
            icon={icons[key]}
            className={classes.icon}
          />
        </a>
      ))}
    </Box>
  )
}
