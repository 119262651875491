import React from "react"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"

import SEO from "../components/seo"
import { Container, Box, Typography, Button } from "@material-ui/core"
import { SiblingLink } from "../components/sibling-link"
import PostSingleHero from "../components/post-single-hero"
import ZigZagImages, { ZigZagImageData } from "../components/zig-zag-images"
import SocialLinks from "./social-links"
import { ArrowBack, CalendarToday, Schedule } from "@material-ui/icons"
import { absUrl } from "../utils"

export default function Single({ data }: any) {
  const {
    markdownRemark,
    prevPage,
    nextPage,
    file,
    paginationItemText,
    backPageLink,
    backPageText,
  } = data
  const {
    frontmatter,
    html,
    timeToRead,
    fields: { slug },
    excerpt,
  } = markdownRemark
  const { title, date, image, imageAlt, gallery, description } = frontmatter

  const galleryItems: ZigZagImageData[] = (gallery || [])
    .filter(item => item.image)
    .map(item => ({
      alt: item.alt,
      fluid: item.image.childImageSharp.fluid,
      publicUrl: item.image.publicURL,
    }))

  const socialLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${absUrl(slug)}`,
    twitter: `https://twitter.com/intent/tweet/?text=${title}&url=${absUrl(
      slug
    )}%2F&via=${file?.frontmatter?.config.sitename}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${absUrl(
      slug
    )}&title=${title}&source=${file?.frontmatter?.config.sitename}}`,
  }

  const prevBtn = () => (
    <Box display="flex" textAlign="right" flexDirection="column">
      <Typography variant="h6">Previous {paginationItemText}</Typography>
      <SiblingLink
        direction="prev"
        title={prevPage.frontmatter.title}
        link={prevPage.fields.slug}
      />
    </Box>
  )

  const nextBtn = () => (
    <Box display="flex" flexDirection="column">
      <Typography variant="h6">Next {paginationItemText}</Typography>
      <SiblingLink
        direction="next"
        title={nextPage.frontmatter.title}
        link={nextPage.fields.slug}
      />
    </Box>
  )

  return (
    <section style={{ paddingTop: "20px" }}>
      <SEO
        location={data.location}
        title={title}
        description={description || excerpt}
        featuredImage={image.childImageSharp.fixed}
      />

      {/* image */}
      <Container>
        <PostSingleHero
          fixedImage={image.childImageSharp.fixed}
          alt={imageAlt}
        />
      </Container>

      {/* title & metadata */}
      <Container maxWidth="md">
        <Box textAlign="left" my={3}>
          <Button
            component={Link}
            to={backPageLink}
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            <ArrowBack />
            <Typography variant="subtitle1">{backPageText}</Typography>
          </Button>
          <Typography variant="h2">{title}</Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <div>
            {data?.showPublishDate && (
              <Box display="flex" alignItems="center" mb={1}>
                <CalendarToday style={{ marginRight: "5px" }} />
                <Typography variant="subtitle2">Published on {date}</Typography>
              </Box>
            )}

            {data?.showReadTime && (
              <Box display="flex" alignItems="center">
                <Schedule style={{ marginRight: "5px" }} />
                <Typography variant="subtitle2">
                  {timeToRead} minute{timeToRead > 1 ? "s" : ""} reading time
                </Typography>
              </Box>
            )}
          </div>

          {data?.showShareLinks && (
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1">Share</Typography>{" "}
              <SocialLinks {...socialLinks} />
            </Box>
          )}
        </Box>

        {/* content */}
        <Box my={3}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Box>

        {/* pagination */}
        <Box display="flex" justifyContent="space-between" mb={6}>
          <div>{prevPage && prevBtn()}</div>
          <div>{nextPage && nextBtn()}</div>
        </Box>
      </Container>

      <Container>
        <ZigZagImages items={galleryItems} />
      </Container>
    </section>
  )
}
