import React from "react"
import Img, { FixedObject } from "gatsby-image"
import { Box } from "@material-ui/core"

export interface PostSingleHeroData {
  fixedImage: FixedObject
  alt: string
}

export default function PostSingleHero({
  fixedImage,
  alt,
}: PostSingleHeroData) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      data-sal="zoom-in"
      style={{
        clipPath: "polygon(0 10%, 100% 0, 100% 100%, 0 90%)",
        marginTop: "-15px",
        marginBottom: "-15px",
      }}
    >
      <Img style={{ maxWidth: "100%" }} fixed={fixedImage} alt={alt} />
    </Box>
  )
}
