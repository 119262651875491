import React, { useState } from "react"

import Img, { FluidObject } from "gatsby-image"
import { makeStyles, Theme, Box } from "@material-ui/core"
import FsLightbox from "fslightbox-react"

const useStyles = makeStyles((theme: Theme) => ({
  first: {
    clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 calc(100% - 80px))",
  },
  even: {
    clipPath: "polygon(0 80px, 100% 0, 100% 100%, 0 calc(100% - 80px))",
    marginTop: "-70px",
  },
  odd: {
    clipPath: "polygon(0 0, 100% 80px, 100% calc(100% - 80px), 0 100%)",
    marginTop: "-70px",
  },
}))

export interface ZigZagImageData {
  publicUrl: string
  fluid: FluidObject
  alt: string
}

export default function ZigZagImages({ items }: { items: ZigZagImageData[] }) {
  const classes = useStyles()
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  const openLightbox = (index: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: index + 1,
    })
  }

  const urls = items.map(item => item.publicUrl)

  return (
    <>
      {items.map((item, index) => {
        let className = ""
        if (index === 0) className = classes.first
        else if (index % 2 === 0) className = classes.even
        else className = classes.odd
        return (
          <Box
            key={"zigzagitem-" + index}
            className={className}
            data-sal={index % 2 == 0 ? "slide-left" : "slide-right"}
            onClick={() => openLightbox(index)}
          >
            <Img fluid={item.fluid} alt={item.alt} />
          </Box>
        )
      })}
      <FsLightbox
        disableThumbs={true}
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={urls}
      />
    </>
  )
}
