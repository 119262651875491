import React from "react"
import { graphql } from "gatsby"
import SinglePage from "../components/single-page"
import { filenameToTitle } from "../utils"

export default function wORKSingle({ data, location }: any) {
  data.markdownRemark.frontmatter.imageAlt = filenameToTitle(
    data.markdownRemark.frontmatter.image.name
  )
  data.markdownRemark.frontmatter.gallery = data.markdownRemark.frontmatter.gallery.map(
    item => {
      item.alt = item.alt || filenameToTitle(item.image.name)
      return item
    }
  )
  return (
    <SinglePage
      data={{
        ...data,
        location,
        paginationItemText: "item",
        backPageLink: "/work",
        backPageText: "Back to work",
        showReadTime: true,
        showSocialLinks: true,
        showPublishDate: true,
      }}
    />
  )
}

export const query = graphql`
  query($slug: String!, $nextPage: String, $prevPage: String) {
    nextPage: markdownRemark(fields: { slug: { eq: $nextPage } }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    prevPage: markdownRemark(fields: { slug: { eq: $prevPage } }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    file(sourceInstanceName: { eq: "data" }, name: { eq: "config" }) {
      config: childMarkdownRemark {
        frontmatter {
          sitename
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      excerpt
      frontmatter {
        title
        description
        rawDate: date
        date(formatString: "DD MMMM YYYY")
        image {
          name
          childImageSharp {
            fixed(width: 1000, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        gallery {
          alt
          image {
            name
            publicURL
            childImageSharp {
              fluid(maxWidth: 1300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      fields {
        slug
      }
      timeToRead
      html
    }
  }
`
